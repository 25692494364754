import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom"

import APICtx, { confApi } from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'
import Dialog, { QrDetails } from '#Dialog'

import { faPlus, faEdit, faTrash, faQrcode, faUsers, faScannerGun, faMapLocation, faPaperPlane } from '@fortawesome/pro-regular-svg-icons'
import EditForm from './Edit'
import { eventTypes } from './def'

const EditEvent = ({ _id, handleClose, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		handleRowRefresh()
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={Boolean(_id) || _id === null}
			title={ (_id ? "Modifica" : "Creazione") + " evento" }
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}

const tabDef = [
	{ label:"Numero", content:row => row?._registry?.number },
	{ label:"Descrizione", field:'description' },
	{ label:"Scadenza form", field:'formsExpiry', type:'date' },
	{ label:"Tipologia", content:row =>
		eventTypes.find(item => item.value===row.type)?.label || '???'
	},
]

export default function EventList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ editEvent, setEditEvent ] = useState()
	const [ qrDetails, setQrDetails ] = useState()
	const navigate = useNavigate()

	const handleRefresh = () => api.call('event/list', {}).then(setIds)
	const handleMailingQR = ({ _id, _registry }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler generale l\'invio qr per l\'evento "' + _registry?.number + '"?',
			onConfirm: () => api.call('event/mailer/prepare-qr', { _id }).then(mailingId => {
				navigate('/mailer/list')
			}),
		})
	}
	const handleDelete = ({ _id, _registry }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler eliminare l\'evento "' + _registry?.number + '"?',
			onConfirm: () => api.call('event/del', { _id }).then(handleRefresh),
		})
	}
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('event/get', { _id })
	const isAdmin = api.auth.chk('events-admin')

	const defBtns = [
		{
			icon: faQrcode,
			label: 'Form di richiesta',
			onClick: row => setQrDetails({
				label: 'Form di richiesta',
				url: confApi.formsUrl + '/conferenza2024/' + row.formJwt,
			}),
		},
		{
			icon: faMapLocation,
			label: 'Mappa dinamica evento',
			onClick: row => setQrDetails({
				label: 'Collegamento mappa evento',
				url: confApi.eventmapUrl + '/' + row.mapJwt,
			}),
		},
		{
			icon: faUsers,
			label: 'Partecipanti',
			linkTo: row => '/event/participant/' + row._id + '/list',
		},
	]
	isAdmin && defBtns.push(
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: setEditEvent,
		},
		{
			icon: faScannerGun,
			label: 'QR sblocco verifiche invitati',
			onClick: row => setQrDetails({
				label: 'Sblocco scan QR',
				url: confApi.qrUrl + '/unlock/' + row.qrUnlockJwt,
			}),
		},
		{
			icon: faPaperPlane,
			label: 'Genera mailing invio QR',
			onClick: handleMailingQR,
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
	)
	
	return (
		<Tpl title="Lista eventi UPA">
			<EditEvent handleRefresh={handleRefresh} handleClose={() => setEditEvent(null)} {...editEvent} />
			<QrDetails handleClose={() => setQrDetails(null)} {...qrDetails} />
			<DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} pageSize={30} />
			{ isAdmin && <Fab label="Crea bando" icon={faPlus} onClick={() => setEditEvent({ _id:null })} /> }
		</Tpl>
	)
}
